import * as React from 'react';
import { Helmet } from 'react-helmet';
import SiteHeader from '@/components/SiteHeader';

const PressPage = () => {
  return (
    <>
      <SiteHeader />
      <main>
        <Helmet>
          <title>Double Turn</title>
        </Helmet>
        <h1>Press</h1>
      </main>
    </>
  );
};

export default PressPage;
